import { ORDERS_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/order',
    name: 'order',
    component: () => import(/* webpackChunkName: "PageOrders" */ '@/modules/oms/pages/PageOrders'),
    meta: {
      permission: ORDERS_ACCESS_SUBJECT,
      title: 'Orders',
      parent: 'dashboard',
    },
  },
  {
    path: '/orders/:uid',
    name: 'orderEditState',
    component: () => import(/* webpackChunkName: "PageOrder" */ '@/modules/oms/pages/PageOrderState'),
    meta: {
      permission: ORDERS_ACCESS_SUBJECT,
      title: 'Order',
      parent: 'order',
    },
  },
  {
    path: '/orders/create/:type',
    name: 'orderCreateState',
    component: () => import(/* webpackChunkName: "PageOrder" */ '@/modules/oms/pages/PageOrderState'),
    meta: {
      permission: ORDERS_ACCESS_SUBJECT,
      title: 'Order Create',
      parent: 'order',
    },
  },
  {
    path: '/saved-queries',
    name: 'savedQueries',
    component: () => import(/* webpackChunkName: "PageSavedQueries" */ '@/modules/oms/pages/PageSavedQueries'),
    meta: {
      permission: ORDERS_ACCESS_SUBJECT,
      title: 'Saved Searches',
      parent: 'dashboard',
    },
  },
];
