import { EXTENSIBILITY_CENTER_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/extensibility-center',
    name: 'extensibility-center',
    component: () => import(/* webpackChunkName: "PageExtensibilityCenter/" */ '@/modules/extensibilityCenter/pages/PageExtensibilityCenter'),
    meta: {
      permission: EXTENSIBILITY_CENTER_ACCESS_SUBJECT,
      title: 'Extensibility center',
      parent: 'dashboard',
    },
  },
];
