import { TICKETING_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/tickets',
    name: 'tickets',
    component: () => import(/* webpackChunkName: "PageTickets" */ '@/modules/tickets/pages/PageTickets'),
    meta: {
      permission: TICKETING_ACCESS_SUBJECT,
      title: 'Tickets',
      parent: 'dashboard',
    },
  },
];
