import { AMS_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/invoice-batches',
    name: 'invoicing',
    component: () => import(/* webpackChunkName: "PageAms" */ '@/modules/invoicing/pages/PageInvoiceBatches'),
    meta: {
      permission: AMS_ACCESS_SUBJECT, // TODO: Should be another subject
      title: 'Invoice Batches',
      parent: 'dashboard',
    },
  },

  {
    path: '/invoice-batches/:uid',
    name: 'invoiceBatchDetails',
    component: () => import(/* webpackChunkName: "PageAms" */ '@/modules/invoicing/pages/PageInvoiceBatch'),
    meta: {
      permission: AMS_ACCESS_SUBJECT, // TODO: Should be another subject
      title: 'Invoice Batch',
      parent: 'invoicing',
    },
  },
];
