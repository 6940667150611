import * as R from 'ramda';
import objectOrArrayToArray from './objectOrArrayToArray';
import makeFilter from './makeFilter';
import makeQuery from './makeQuery';

const makeElasticSearchQuery = ({
  fields, string, filters, limit, page, query, defaultOrder,
}) => {
  // If string doesn't contains letters -> replace all spaces
  let searchString = string;
  if (!/[a-zA-Z]/.test(string)) {
    searchString = searchString.replace(/ /g, '');
  }

  const queryObj = {
    bool: {},
  };

  // Full text (phrase) search
  if (fields) {
    queryObj.bool = {
      should: R.flatten(makeQuery(fields, searchString)),
      minimum_should_match: 1,
    };
  }

  // Add filters
  if (filters) {
    const filtersArray = objectOrArrayToArray(filters);
    queryObj.bool.filter = makeFilter(filtersArray);
  }

  const searchQuery = {
    _page: {
      limit: limit || 10,
      page: page || 1,
    },
    query: query || JSON.stringify(queryObj),
  };

  // Add parameters for sorting
  if (defaultOrder) {
    if (Array.isArray(defaultOrder)) {
      searchQuery._sort = defaultOrder;
    } else {
      searchQuery._sort = [defaultOrder];
    }
  }

  return searchQuery;
};

export default makeElasticSearchQuery;
