export default [
  {
    path: '/apxreports',
    name: 'apxreports',
    component: () => import(/* webpackChunkName: "PageReports" */ '@/modules/reporting/pages/PageReports'),
    meta: {
      title: 'APX reports',
    },
  },
];
