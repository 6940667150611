import { PRICING_MANAGEMENT_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/pricing/models',
    name: 'pricing-models',
    component: () => import(/* webpackChunkName: "PagePricingModels" */ '@/modules/pricing/pages/PagePricingModels'),
    meta: {
      title: 'Pricing Models List',
      permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
      parent: 'dashboard',
    },
  },

  {
    path: '/pricing/model-details/:uid',
    name: 'pricing-model-details',
    component: () => import(/* webpackChunkName: "PagePricingModelDetails" */ '@/modules/pricing/pages/PagePricingModelDetails'),
    meta: {
      permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
      parent: 'pricing-models',
      title: 'Pricing Model Details',
    },
  },

  {
    path: '/pricing/model-details/:uid/element/:elementUid',
    name: 'pricing-element-details',
    component: () => import(/* webpackChunkName: "PagePricingElementDetails" */ '@/modules/pricing/pages/PagePricingElementDetails'),
    meta: {
      permission: PRICING_MANAGEMENT_ACCESS_SUBJECT,
      parent: 'pricing-model-details',
      title: 'Pricing Element Details',
    },
  },
];
