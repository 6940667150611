import { EMAIL_LOG_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/email-logs',
    name: 'email-logs',
    component: () => import(/* webpackChunkName: "PageEmailLog" */ '@/modules/emailLog/pages/PageEmailLog'),
    meta: {
      permission: EMAIL_LOG_ACCESS_SUBJECT,
      title: 'Email Log',
      parent: 'dashboard',
    },
  },
];
