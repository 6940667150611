import { EQUIPMENT_RECORDS_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/equipment-records',
    name: 'equipment-records',
    component: () => import(/* webpackChunkName: "PageEquipmentRecords" */ '@/modules/equipmentRecords/pages/PageEquipmentRecords'),
    meta: {
      title: 'Equipment Records',
      permission: EQUIPMENT_RECORDS_ACCESS_SUBJECT,
      parent: 'dashboard',
    },
  },

  {
    path: '/equipment-records/:uid',
    name: 'equipment-record',
    component: () => import(/* webpackChunkName: "PageEquipmentRecordDetails" */ '@/modules/equipmentRecords/pages/PageEquipmentRecordDetails'),
    meta: {
      title: 'Equipment Record',
      permission: EQUIPMENT_RECORDS_ACCESS_SUBJECT,
      parent: 'equipment-records',
    },
  },
];
