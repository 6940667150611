import { AMS_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/ams',
    name: 'ams',
    component: () => import(/* webpackChunkName: "PageAms" */ '@/modules/ams/pages/PageAms'),
    meta: {
      permission: AMS_ACCESS_SUBJECT,
      title: 'Access management service',
      parent: 'extensibility-center',
    },
  },
];
