export const LOCAL_STORAGE_PREFIX = 'viax-';

// History keys
export const ORGANIZAIONS_HISTORY_KEY = 'organizations';
export const PERSONS_HISTORY_KEY = 'persons';
export const PERSONE_USER = 'User';

/**
 * format of date string
 *
 * @type {string}
 */
export const FORMAT_OF_DATE = 'MM/dd/yyyy';
export const FORMAT_OF_DATE_FOR_SAVE = 'yyyy-MM-dd';
export const FORMAT_OF_DATE_EXTENDED = 'MMM dd, yyyy';
export const FORMAT_OF_DATE_EXTENDED_TIME = 'MMM dd, yyyy h:mm a';
export const FORMAT_OF_DATE_EXTENDED_TIME_FOR_SERVER = "yyyy-MM-dd'T'HH:mm:ss'Z'";
export const FORMAT_OF_DATE_SECOND = 'dd MMM yyyy';
export const FORMAT_OF_DATE_THIRD = 'MM/dd/yyyy h:mm a';
export const FORMAT_OF_TIME = 'h:mm a';

export const MASK_OF_PHONE_NUMBER = '(###) ###-####';
export const MASK_OF_ZIP_CODE_DEFAULT = '#####-####';
export const MASK_OF_ZIP_CODE_US = '#####-####';
export const MASK_OF_ZIP_CODE_CA = 'A#A #A#';

export const CANCELLED_STATUS = 'CANCELLED';
/**
 * Countries
 */
export const countries = {
  US: {
    name: 'United States',
    nameOfProvinces: 'State',
    placeholderForProvinces: 'Enter state',
    zipCodeMask: MASK_OF_ZIP_CODE_US,
  },

  CA: {
    name: 'Canada',
    nameOfProvinces: 'Province',
    placeholderForProvinces: 'Enter province',
    zipCodeMask: MASK_OF_ZIP_CODE_CA,
  },
};

/**
 * Commissions defaults
 *
 * @type String | {Object}
 */
export const DEFAULT_COMMISSION_PLAN = 'FOLLOW_BILLING_PLAN';
export const DEFAULT_COMMISSION_PAYABLE = 'INVOICE_PAYMENT';
export const DEFAULT_COMMISSION_TYPES = {
  PRODUCT_BASED: 'PRODUCT_BASED',
  FIXED: 'FIXED',
};

/**
 * Types of errors during invoice creation
 *
 * @type {Object}
 */
export const INVOICE_ERROR_TYPES = {
  BILLING_LINES: 'BILLING_LINES',
};

export const SEARCH_TYPES = {
  ORDERS: 'ORDERS',
  ORGANIZATIONS: 'ORGANIZATIONS',
  POLICY: 'POLICY',
  PRICING_MODELS: 'PRICING_MODELS',
  PRODUCTS: 'PRODUCTS',
  PRODUCT_CONFIGURATIONS: 'PRODUCT_CONFIGURATIONS',
  TICKETS: 'TICKETS',
  TYPES: 'TYPES',
  USERS_AND_CONTACTS: 'USERS_AND_CONTACTS',
  WORKFLOWS: 'WORKFLOWS',
  ATTRIBUTES: 'ATTRIBUTES',
  GROUPS: 'GROUPS',
  ROLES: 'ROLES',
  EMAIL_LOGS: 'EMAIL_LOGS',
  EQUIPMENT_RECORDS: 'EQUIPMENT_RECORDS',
  INVOICE_BATCHES: 'INVOICE_BATCHES',
  PROJECTS: 'PROJECTS',
  SAVED_QUERIES: 'SAVED_QUERIES',
};

// Breakpoints
export const HEADER_BREAK_POINT_MOBILE = 740;
export const TABLET_MEDIA_BREAKPOINT = 768;
export const DESKTOP_MEDIA_BREAKPOINT = 1024;

// Message bus possible subjects
export const MESSAGE_BUS = {
  UPDATE_POLICY_SETS: 'UPDATE_POLICY_SETS',
};

// Product configuration items
export const CONFIG_ITEMS = {
  SEGMENT: 'SEGMENT',
  COMPONENT: 'COMPONENT',
  OPTION: 'OPTION',
};
