import { PRODUCT_CONFIG_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/product-config/models',
    name: 'product-config',
    component: () => import(/* webpackChunkName: "PageProductModels" */ '@/modules/productConfiguration/pages/PageProductModels'),
    meta: {
      permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
      title: 'Models List',
      parent: 'dashboard',
    },
  },

  {
    path: '/product-config/model-details/:uid',
    name: 'model-details',
    component: () => import(/* webpackChunkName: "PageProductModelDetails" */ '@/modules/productConfiguration/pages/PageProductModelDetails'),
    meta: {
      permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
      parent: 'product-config',
      title: 'Model Details',
    },
  },
];
