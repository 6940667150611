import { AbilityBuilder, Ability } from '@casl/ability';

// User roles
const ADMIN_USER_ROLE = 'admin';
const TICKETING_USER_ROLE = 'ticketing';
const CRM_USER_ROLE = 'crm';
const ENGINEER_USER_ROLE = 'engineer';
const EMPLOYEE_USER_ROLE = 'employee';

// Subjects
export const PRODUCT_CONFIG_ACCESS_SUBJECT = 'Product Config';
export const TICKETING_ACCESS_SUBJECT = 'Tickets';
export const CRM_ACCESS_SUBJECT = 'CRM';
export const ORDERS_ACCESS_SUBJECT = 'Orders';
export const PRODUCT_MANAGEMENT_ACCESS_SUBJECT = 'Product Management';
export const PRICING_MANAGEMENT_ACCESS_SUBJECT = 'Pricing Management';
export const BILLING_ACCESS_SUBJECT = 'Billing';
export const EMAIL_LOG_ACCESS_SUBJECT = 'Email Logs';
export const CUSTOMIZATION_ACCESS_SUBJECT = 'Customization Center';
export const EXTENSIBILITY_CENTER_ACCESS_SUBJECT = 'Extensibility Center';
export const AMS_ACCESS_SUBJECT = 'Access management service';
export const WORKFLOW_ACCESS_SUBJECT = 'Workflow';
export const ROLES_ACCESS_SUBJECT = 'Roles & Permissions';
export const EQUIPMENT_RECORDS_ACCESS_SUBJECT = 'Equipment Records';
export const PROJECTS_ACCESS_SUBJECT = 'Projects';

export const defineAbilitiesFor = (roles) => {
  const { can, cannot, build } = new AbilityBuilder(Ability);

  if (roles.includes(TICKETING_USER_ROLE)) {
    can('manage', TICKETING_ACCESS_SUBJECT);
  }
  if (roles.includes(ENGINEER_USER_ROLE)) {
    can('manage', ORDERS_ACCESS_SUBJECT);
    cannot(['create', 'remove'], ORDERS_ACCESS_SUBJECT);
    cannot('read', PRICING_MANAGEMENT_ACCESS_SUBJECT);
  }
  if (roles.includes(CRM_USER_ROLE)) {
    can('manage', CRM_ACCESS_SUBJECT);
  }
  if (roles.includes(ADMIN_USER_ROLE)) {
    can('manage', 'all');
  }
  if (roles.includes(EMPLOYEE_USER_ROLE)) {
    can('manage', 'all');
  }

  return build();
};
