const SET_HIDDEN = 'SET_HIDDEN';
const SET_VISIBLE = 'SET_VISIBLE';
const SET_REQUIRED = 'SET_REQUIRED';
const SET_OPTIONAL = 'SET_OPTIONAL';
const SET_READONLY = 'SET_READONLY';
const SET_CHANGEABLE = 'SET_CHANGEABLE';
const SET_COMPONENT_VALUE = 'SET_COMPONENT_VALUE';
const CALCULATE_LAMBDA_FUNCTION = 'CALCULATE_LAMBDA_FUNCTION';
const ADD_NOTIFICATION = 'ADD_NOTIFICATION';
const ADD_CONFLICT = 'ADD_CONFLICT';

const actionTypes = [
  {
    label: 'Set hidden',
    value: SET_HIDDEN,
  },
  {
    label: 'Set visible',
    value: SET_VISIBLE,
  },
  {
    label: 'Set required',
    value: SET_REQUIRED,
  },
  {
    label: 'Set optional',
    value: SET_OPTIONAL,
  },
  {
    label: 'Set readonly',
    value: SET_READONLY,
  },
  {
    label: 'Set changeable',
    value: SET_CHANGEABLE,
  },
  {
    label: 'Set component value',
    value: SET_COMPONENT_VALUE,
  },
  {
    label: 'Calculate lambda function',
    value: CALCULATE_LAMBDA_FUNCTION,
  },
  {
    label: 'Add notification',
    value: ADD_NOTIFICATION,
  },
  {
    label: 'Add conflict',
    value: ADD_CONFLICT,
  },
];

export {
  SET_HIDDEN,
  SET_VISIBLE,
  SET_REQUIRED,
  SET_OPTIONAL,
  SET_COMPONENT_VALUE,
  ADD_NOTIFICATION,
  ADD_CONFLICT,
  CALCULATE_LAMBDA_FUNCTION,
  actionTypes,
};
