/**
 *  @module util/mapEnums - Helper for mapping enum types to strings
 *
 * @version 1.0.0
 * @copyright viax.io 2018
 */
import { snakeCaseToTitle, snakeCaseToKebab } from '@viax/js-utils';

import { ORDER_TYPES_AVAILABLE } from '@/config/order';

export const COMMISSION_PLANS = {
  ONE_TIME: 'One-time',
  FOLLOW_BILLING_PLAN: 'Follow billing plan',
};

export const COMMISSION_PAYABLE = {
  INVOICE_CREATION: 'Invoice Creation',
  INVOICE_PAYMENT: 'Invoice Payment',
};

export const LEDGER_ENTRIES_TYPES = {
  CloseAllContractsInvoiceCustomerLedgerEntry: 'Close Contracts Invoices',
  CloseContractCreditCustomerLedgerEntry: 'Credit',
  InvoiceCustomerLedgerEntry: 'Invoices',
  LateFeeCustomerLedgerEntry: 'Fee',
  ManualCreditAdjustmentCustomerLedgerEntry: 'Manual Credit Adjustment',
  ManualCreditCustomerLedgerEntry: 'Manual Credit',
  ManualDebitAdjustmentCustomerLedgerEntry: 'Manual Debit Adjustment',
  ManualFeeCustomerLedgerEntry: 'Manual Fee',
  PaymentCustomerLedgerEntry: 'Payments',
  WireTransferLateFeeCustomerLedgerEntry: 'Wire Transfer Late Fee',
  DMARCMoveManualFeeCustomerLedgerEntry: 'DMARC Move Manual Fee',
  AdministrativeManualFeeCustomerLedgerEntry: 'Administrative Manual Fee',
  CopyrightInfringementManualFeeCustomerLedgerEntry: 'Copyright Infringement Manual Fee',
  CreditCardManualFeeCustomerLedgerEntry: 'Credit Card Manual Fee',
  DisconnectManualFeeCustomerLedgerEntry: 'Disconnect Manual Fee',
  DocumentResearchManualFeeCustomerLedgerEntry: 'Document Research Manual Fee',
  EquipmentChargeManualFeeCustomerLedgerEntry: 'Equipment Charge Manual Fee',
  EquipmentShippingManualFeeCustomerLedgerEntry: 'Equipment Shipping Manual Fee',
  ReconnectManualFeeCustomerLedgerEntry: 'Reconnect Manual Fee',
  ReturnedCheckManualFeeCustomerLedgerEntry: 'Returned Check Manual Fee',
  ServiceTransferManualFeeCustomerLedgerEntry: 'Service Transfer Manual Fee',
  ShippingChargeManualFeeCustomerLedgerEntry: 'Shipping Charge Manual Fee',
  TerminationChargeManualFeeCustomerLedgerEntry: 'Termination ChargeM anual Fee',
  TransferOfOwnershipManualFeeCustomerLedgerEntry: 'Transfer Of Ownership Manual Fee',
  TruckRollManualFeeCustomerLedgerEntry: 'Truck Roll Manual Fee',
  UnreturnedEquipmentManualFeeCustomerLedgerEntry: 'Unreturned Equipment Manual Fee',
  WireTransferManualFeeCustomerLedgerEntry: 'Wire Transfer Manual Fee',
  CustomerRefund: 'Customer Refund',
};

export const PRODUCT_RELATION_TYPES = {
  ATTACHMENT: 'Attachment',
  REPLACES: 'Replaces',
  SPARE_PART: 'Spare Part',
  GLOBAL_MODEL: 'Global Model',
  REGIONAL_MODEL: 'Regional Model',
};

export const PRODUCT_RELATION_SIDE = {
  IS: 'Is',
  HAS: 'Has',
};

export const mapEnumsToValueAndLabel = ({ name }) => ({
  label: snakeCaseToTitle(name),
  value: name,
});

export const mapEnumsToOrderActions = ({ name }) => ({
  action: snakeCaseToKebab(name),
  disable: !ORDER_TYPES_AVAILABLE.includes(name),
  title: snakeCaseToTitle(name),
  value: name,
});
