import { CUSTOMIZATION_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/customization-center',
    name: 'customization-center',
    component: () => import(/* webpackChunkName: "PageCustomizationCenter" */ '@/modules/customizationCenter/pages/PageCustomizationCenter'),
    meta: {
      permission: CUSTOMIZATION_ACCESS_SUBJECT,
      title: 'Customization Center',
      parent: 'extensibility-center',
    },
  },
];
