import { WORKFLOW_ACCESS_SUBJECT } from '@/utils';

export default [
  // Workflow module
  {
    path: '/workflow',
    name: 'workflow',
    component: () => import(/* webpackChunkName: "PageWorkflows" */ '@/modules/workflow/pages/PageWorkflows'),
    meta: {
      permission: WORKFLOW_ACCESS_SUBJECT,
      title: 'Workflows',
      parent: 'extensibility-center',
    },
  },
  {
    path: '/workflow/create/:selectedType',
    name: 'workflowCreate',
    component: () => import(/* webpackChunkName: "PageWorkflow" */ '@/modules/workflow/pages/PageWorkflow'),
    meta: {
      permission: WORKFLOW_ACCESS_SUBJECT,
      title: 'Workflow',
      parent: 'workflow',
    },
  },
  {
    path: '/workflow/:uid',
    name: 'workflowEdit',
    component: () => import(/* webpackChunkName: "PageWorkflow" */ '@/modules/workflow/pages/PageWorkflow'),
    meta: {
      permission: WORKFLOW_ACCESS_SUBJECT,
      title: 'Workflow',
      parent: 'workflow',
    },
  },
];
