import { ROLES_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/roles',
    name: 'roles',
    component: () => import(/* webpackChunkName: "PageCustomizationCenter" */ '@/modules/roles/pages/PageRoles'),
    meta: {
      permission: ROLES_ACCESS_SUBJECT,
      title: 'Roles & Permissions',
      parent: 'extensibility-center',
    },
  },
];
