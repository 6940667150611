import { BILLING_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/billing',
    name: 'billing',
    component: () => import(/* webpackChunkName: "PageBilling" */ '@/modules/billing/pages/PageBilling'),
    redirect: { name: 'ledger' },
    meta: {
      permission: BILLING_ACCESS_SUBJECT,
      title: 'Billing',
    },
    children: [
      {
        path: 'invoices',
        name: 'invoices',
        component: () => import(/* webpackChunkName: "PageInvoices" */ '@/modules/billing/pages/PageInvoices'),
        meta: {
          title: 'Invoices',
          parent: 'dashboard',
        },
      },
      {
        path: 'payments',
        name: 'payments',
        component: () => import(/* webpackChunkName: "PagePayments" */ '@/modules/billing/pages/PagePayments'),
        meta: {
          title: 'Payments',
          parent: 'dashboard',
        },
      },
      {
        path: 'ledger',
        name: 'ledger',
        component: () => import(/* webpackChunkName: "PageLedger" */ '@/modules/billing/pages/PageLedger'),
        meta: {
          title: 'Ledger',
          parent: 'dashboard',
        },
      },
    ],
  },
  {
    path: '/billing/ledger/:uid/details',
    name: 'ledger-details',
    component: () => import(/* webpackChunkName: "PageCustomerLedgerDetails" */ '@/modules/billing/pages/PageCustomerLedgerDetails'),
    meta: {
      parent: 'ledger',
      title: 'Ledger details',
    },
  },
];
