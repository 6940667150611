import { PRODUCT_CONFIG_ACCESS_SUBJECT, PRODUCT_MANAGEMENT_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/product-catalog/products-list',
    name: 'products-list',
    component: () => import(/* webpackChunkName: "PageProducts" */ '@/modules/product/pages/PageProducts'),
    meta: {
      title: 'Products List',
      permission: PRODUCT_MANAGEMENT_ACCESS_SUBJECT,
      parent: 'dashboard',
    },
  },

  {
    path: '/product-catalog/product-groups',
    name: 'product-groups',
    component: () => import(/* webpackChunkName: "PageProductGroups" */ '@/modules/product/pages/PageProductGroups'),
    meta: {
      permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
      parent: 'dashboard',
      title: 'Groups',
    },
  },

  {
    path: '/product-catalog/product-attributes',
    name: 'product-attributes',
    component: () => import(/* webpackChunkName: "PageProductAttributes" */ '@/modules/product/pages/PageProductAttributes'),
    meta: {
      permission: PRODUCT_CONFIG_ACCESS_SUBJECT,
      parent: 'dashboard',
      title: 'Attributes',
    },
  },
];
