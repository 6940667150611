/**
 * This is a helper for deep deleting __typename from object
 * use before mutations
 *
 * @param obj
 * @returns {any}
 */
export const stripTypename = (obj) => JSON.parse(JSON.stringify(obj, (k, v) => (k === '__typename' ? undefined : v)));

/**
 * This is a helper for extracting a number from a string
 * need for sorting list
 *
 * @param str
 * @returns {number}
 */
export const extractNumber = (str) => {
  if (!str || typeof str !== 'string') return 0;
  return parseInt(str.match(/\d/g).join(''), 10);
};

export const valueToLocale = (obj, values) => {
  let refactoredObj = obj;
  values.forEach((value) => {
    refactoredObj = JSON.parse(JSON.stringify(refactoredObj, (k, v) => (k === value ? { node: v } : v)));
  });
  return refactoredObj;
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});
