import { CRM_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/customer',
    name: 'customer',
    component: () => import(/* webpackChunkName: "PageCustomer" */ '@/modules/crm/pages/PageCustomer'),
    meta: {
      permission: CRM_ACCESS_SUBJECT,
      title: 'Organizations',
      parent: 'dashboard',
    },
  },
];
