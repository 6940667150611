import { PROJECTS_ACCESS_SUBJECT } from '@/utils';

export default [
  {
    path: '/projects',
    name: 'projects',
    component: () => import(/* webpackChunkName: "PageProjects" */ '@/modules/projects/pages/PageProjects'),
    meta: {
      title: 'Projects',
      permission: PROJECTS_ACCESS_SUBJECT,
      parent: 'dashboard',
    },
  },
];
