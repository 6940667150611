import { createApp } from 'vue';
import VueKeyCloak from '@dsb-norge/vue-keycloak-js';
import VueTheMask from 'vue-the-mask';
import { createGtm } from 'vue-gtm';
import { abilitiesPlugin } from '@casl/vue';
import { log } from '@viax/js-utils';
import {
  NotificationsPlugin,
  preventClickOnSelect,
} from '@viax/component-library';
import '@viax/ui-components-default-theme';

import { install as apolloProvider } from '@viax/apollo-provider';
import { defineAbilitiesFor, getUserRoles } from '@/utils';
import App from './App';
import createRouter from './router';
import store from './store';

const app = createApp(App);

app.directive('prevent-click-on-select', preventClickOnSelect);

const options = {
  config: {
    realm: process.env.VUE_APP_KEYCLOAK_AUTH_REALM,
    url: process.env.VUE_APP_KEYCLOAK_AUTH_AUTH_URL,
    clientId: process.env.VUE_APP_KEYCLOAK_AUTH_AUTH_CLIENT_ID,
    // logoutRedirectUri: '',
  },
  init: {
    onLoad: 'login-required',
    checkLoginIframe: false,
  },
  onReady: (keycloak) => {
    // Define Abilities
    const ability = defineAbilitiesFor(getUserRoles(keycloak.tokenParsed));
    // Setting permissions for the current user
    app.use(abilitiesPlugin, ability, {
      useGlobalProperties: true,
    });

    const router = createRouter(ability);
    app.use(router);
    app.use(store);

    if (process.env.NODE_ENV === 'production') {
      app.use(createGtm({
        id: 'GTM-N5H9DNM',
        defer: false, // defaults to false. Script can be set to `defer` to increase page-load-time at the cost of less accurate results (in case visitor leaves before script is loaded, which is unlikely but possible)
        enabled: true, // defaults to true. Plugin can be disabled by setting this to false for Ex: enabled: !!GDPR_Cookie (optional)
        debug: true, // Whether or not display console logs debugs (optional)
        loadScript: true, // Whether or not to load the GTM Script (Helpful if you are including GTM manually, but need the dataLayer functionality in your components) (optional)
        vueRouter: router, // Pass the router instance to automatically sync with router (optional)
      }));
    }

    app.use(apolloProvider, {
      log,
      url: process.env.VUE_APP_GRAPHQL_ENDPOINT,
      keycloak,
    });

    app.use(NotificationsPlugin, {
      timeout: 3000,
    });

    app.use(VueTheMask);
    app.mount('#app');
  },
  onInitError: (error) => {
    console.log('INIT ERROR CONSOLE: ', error);
  },
};

app.use(VueKeyCloak, options);
